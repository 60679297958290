<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-gutter-sm">
        <h6 class="title-h6">{{$t('processes.portefeuille')}}</h6>
        <div class="row">
          <add-a-wallet :max="1" v-if="currentPtfConfie && currentPtfConfie.wallets" @walletsUpdated="updateWallet" v-model="currentPtfConfie.wallets" :wallets="currentPtfConfie.wallets" class="col"/>
          <add-a-wallet :max="1" v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class=" col" v-bind="formInputProps('taux_detention')" :color="color"
                v-model="formData.taux_detention" ref="label" />
              <base-input-select class="col q-mt-none" v-bind="formInputProps('type_reorganisation')" :color="color"
                v-model="formData.type_reorganisation" ref="label" />
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class=" col" v-bind="formInputProps('libelle_entite_agent')" :color="color"
                v-model="formData.libelle_entite_agent" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('nom_adresse_expert_incendie')" :color="color"
                v-model="formData.nom_adresse_expert_incendie" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('ptfConfie.IC')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class=" col" v-bind="formInputProps('date_demande_listing_IC_IA')" :color="color"
                v-model="formData.date_demande_listing_IC_IA" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_demande_calcul_IC_VIE')" :color="color"
                v-model="formData.date_demande_calcul_IC_VIE" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('ptfConfie.formulaire_cbc')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reception_formulaire_CBC ')" :color="color"
                v-model="formData.date_reception_formulaire_CBC " ref="" />
              <base-input-date class="col" v-bind="formInputProps('date_reception_RIB_CBC')" :color="color"
                v-model="formData.date_reception_RIB_CBC" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reception_RIB_compte_frais_generaux')" :color="color"
                v-model="formData.date_reception_RIB_compte_frais_generaux" ref="label" />
              <div class="col" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('ptfConfie.mandat_sfpj')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class=" col" v-bind="formInputProps('date_demande_mandat_SFPJ')" :color="color"
                v-model="formData.date_demande_mandat_SFPJ" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_signature_mandat_SFPJ')" :color="color"
                v-model="formData.date_signature_mandat_SFPJ" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('ptfConfie.avis_au_services')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
            <base-input-date class=" col" v-bind="formInputProps('date_emission_avis_service')" :color="color"
              v-model="formData.date_emission_avis_service" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_des_ic')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviIc.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviIc in relatedSuiviIc" :key="suiviIc.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviIc.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-des-ic-form', params: { id: suiviIc.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_des_ic')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_linked_suivi_des_ic')" :disabled="!this.$route.params.id" :color="color" @click="createLinkedProcess('suiviIc')" />
          </div>
        </div>
      </div>
    </div>
    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click="destroy" :disabled="!$route.params.id">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { BaseForm } from '../../../mixins'
import { mapGetters } from 'vuex'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'ptfConfie' },
    'action': { type: String, default: 'ptfConfie/savePtfConfie' }
  },
  data () {
    return {
      linkedProcess: this.$route.meta.process,
      linkedProcessId: this.$route.params.processId,
      // Related processes
      relatedProcessLinked: []
    }
  },
  methods: {
    onSave ({ id }) {
      this.isUpdate = true
      if (id) {
        this.formData.id = id
        this.$router.push({ name: 'portefeuille-confie-fiche', params: { id } })
      }
    },
    copyAnnonce () {

    },
    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('ptfConfie/deletePtfConfie', this.$route.params.id).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    createLinkedProcess (process) {
      this.$store.dispatch('processes/createLinkedProcess', { linkedProcess_type: process, process_id: this.$route.params.id, process: 'portefeuilleConfie' }).then(datas => {
        if (!this.formData.related_objects) this.formData.related_objects = []
        datas.forEach(linkedObj => {
          console.log(linkedObj)
          this.relatedProcessLinked.push({
            id: linkedObj.id,
            type: process,
            wallets: linkedObj.wallets,
            agents: linkedObj.agents,
            actions: [
              { label: this.$t(`processes.unlink_${process}`), payload: { name: `unlink-${process}`, params: { id: linkedObj.id } } }
            ]
          })
          this.formData.related_objects.push({
            model_type: process,
            model_id: linkedObj.id
          })
        })
      })
    },

    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'unlink-suiviIc') {
        this.relatedSuiviIc = this.relatedSuiviIc.filter(suiviIc => suiviIc.id !== params.id)
      }
    }
  },
  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('ptfConfie/fetchCurrentPtfConfie', currentRoute.params.id).then(() => {
        store.dispatch('ptfConfie/fetchPtfConfieFormFields')
      })
    } else {
      store.dispatch('ptfConfie/fetchPtfConfieFormFields')
    }
  },
  beforeDestroy () {
    this.$store.commit('ptfConfie/setCurrentPtfConfie', null)
  },
  mounted () {
    if (this.linkedProcessId) {
      this.formData.related_objects = []
      this.formData.related_objects.push({
        model_type: this.linkedProcess,
        model_id: this.linkedProcessId
      })
    }
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_nomination'))
  },
  watch: {
    formFields: {
      handler (newVal, oldVal) {
        if (!newVal) return
        if (!this.currentPtfConfie) {
          // Set default value for statut
          this.formData.statut = {}
          this.formData.statut.label = 'ptfConfie.fields.statut.options.EN_COURS'
          this.formData.statut.value = 'EN_COURS'

          return
        }

        console.log(this.currentPtfConfie.date_signature_mandat_SFPJ)
        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => {
              let idTrimed = id.trim()
              console.log(idTrimed)
              console.log('date_signature_mandat_SFPJ')
              console.log(idTrimed === 'date_signature_mandat_SFPJ')
              console.log(this.currentPtfConfie[id])
              return {
                ...acc,
                [idTrimed]: options ? options.find(({ value }) => value === this.currentPtfConfie[idTrimed]) : this.currentPtfConfie[idTrimed]
              }
            }, {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          console.log(this.currentPtfConfie.date_signature_mandat_SFPJ, this.formData.date_signature_mandat_SFPJ)

          this.formData.related_objects = []
          // Set wallets data
          let walletsMapped = this.currentPtfConfie.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          // Set nomination
          this.formData.related_objects.push({ model_type: 'nomination', model_id: this.currentPtfConfie.nominations[0].id })

          // Set process suivi IC lié
          if (this.currentPtfConfie.suivi_ics) {
            let suiviIcFormDataMapped = this.currentPtfConfie.suivi_ics.map(suiviIc => ({
              model_type: 'suiviIc',
              model_id: suiviIc.id
            }))
            this.formData.related_objects.push(...suiviIcFormDataMapped)

            let suiviIcLinkedMapped = this.currentPtfConfie.suivi_ics.map(suiviIc => ({
              type: 'suiviIc',
              id: suiviIc.id,
              wallets: suiviIc.wallets,
              agents: suiviIc.agents,
              actions: [
                { label: this.$t('processes.unlink_suiviIc'), payload: { name: 'unlink-suiviIc', params: { id: suiviIc.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviIcLinkedMapped)
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      currentPtfConfie: 'ptfConfie/getCurrentPtfConfie'
    }),

    relatedSuiviIc: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'suiviIc') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'suiviIc')
        this.formData.related_objects.push(...newVal.map(suiviIc => ({ model_type: 'suiviIc', model_id: suiviIc.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'suiviIc')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  }
}
</script>
